


























import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    ORegistrationFees: () =>
      import(
        "@/components/organisms/event/summaryInfo/o-registration-fees.vue"
      ),
    OAccommodation: () =>
      import("@/components/organisms/event/summaryInfo/o-accommodation.vue"),
    OServices: () =>
      import("@/components/organisms/event/summaryInfo/o-services.vue"),
    OParticipantGroups: () =>
      import(
        "@/components/organisms/event/summaryInfo/o-participant-groups.vue"
      ),
  },
  setup(_, { root }) {
    const state = reactive({
      currency: "",
    });

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.currency = event.currency;
        })
        .catch((error) => console.log(error));
    };

    onMounted(fetchEvent);
    return { state };
  },
});
